import { Cart } from "@commercetools/platform-sdk"
import * as Sentry from "@sentry/browser"
import { FormikProps } from "formik"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { useAxiosClient } from "../../../AxiosClient"
import { paymentApiUrl } from "../../../Config"
import { useTypedErrorApi } from "../../../error/ErrorHooks"
import { ErrorType } from "../../../error/ErrorType"
import { useStoreContext } from "../../../i18n/StoreHooks"
import { useAppDispatch } from "../../../State"
import {
  FormRef,
  success,
  useValidationStrategy,
  ValidationStrategy
} from "../../validation/FormValidator"
import { error, ValidationResult } from "../../validation/ValidationError"
import { CancelledPendingPayments, GiftCard } from "../GiftCards"
import { PaymentProvider, PaymentProviderState } from "../PaymentProviderState"
import { useScrollToElement } from "../ScrollToHook"
import { GiftCardState, useGiftCardState } from "../GiftCardState"

export enum BillingAddressType {
  SameAsShipping = 0,
  NewAddress = 1,
  SavedAddress = 2
}

export const usePaymentErrorMessage = () => {
  const { t } = useTranslation("checkout")
  return {
    title: t("paymentFailedTitle"),
    message: t("paymentFailedMessage")
  }
}

export const useEmptyPaymentErrorMessage = () => {
  const { t } = useTranslation("checkout")
  return {
    title: t("paymentMethod"),
    message: t("paymentMethodEmptyErrorMessage")
  }
}

export const useEmptyPaymentMethod = (
  paymentProviders: PaymentProvider[]
): ValidationStrategy => {
  const message = useEmptyPaymentErrorMessage()
  return useValidationStrategy(() => paymentProviders.length === 0, {
    name: "NothingSelected",
    validate: () => error(ErrorType.PaymentError, message),
    save: () => error(ErrorType.PaymentError, message)
  })
}

export const useBorgunPaymentMethod = (
  paymentProviders: PaymentProvider[]
): ValidationStrategy & FormRef<unknown> => {
  const dispatch = useAppDispatch()
  const { isShoppingInternationally } = useStoreContext()
  const formRef = useRef<FormikProps<unknown>>()
  const message = usePaymentErrorMessage()
  const emptyPayment = useEmptyPaymentErrorMessage()

  const strategy = useValidationStrategy(
    () => paymentProviders.indexOf(PaymentProvider.Borgun) > -1,
    {
      name: "BorgunValidator",
      validate: () => {
        if (isShoppingInternationally()) {
          return error(ErrorType.PaymentError, message)
        }
        if (!formRef?.current) {
          return error(ErrorType.PaymentError, message)
        }
        return formRef.current.validateForm().then(() => {
          if (formRef.current?.isValid) {
            return success()
          } else {
            return error(ErrorType.PaymentError, emptyPayment)
          }
        })
      },
      save(): ValidationResult {
        return Promise.resolve(
          dispatch(
            PaymentProviderState.actions.addPaymentProvider(
              PaymentProvider.Borgun
            )
          )
        )
          .then(() => formRef.current?.submitForm())
          .then(() => success())
      }
    }
  )

  return {
    formRef,
    ...strategy
  }
}

export const useNetgiroPaymentMethod = (
  paymentProviders: PaymentProvider[]
): ValidationStrategy => {
  const dispatch = useAppDispatch()
  const { isShoppingInternationally } = useStoreContext()
  const message = usePaymentErrorMessage()

  return useValidationStrategy(
    () => paymentProviders.indexOf(PaymentProvider.NetGiro) > -1,
    {
      name: "NetgiroValidator",
      validate: () => {
        if (isShoppingInternationally()) {
          return error(ErrorType.PaymentError, message)
        }
        return success()
      },
      save(): ValidationResult {
        return Promise.resolve(
          dispatch(
            PaymentProviderState.actions.addPaymentProvider(
              PaymentProvider.NetGiro
            )
          )
        ).then(() => success())
      }
    }
  )
}

export const usePayPalPaymentMethod = (
  paymentProviders: PaymentProvider[]
): ValidationStrategy => {
  const dispatch = useAppDispatch()
  const message = usePaymentErrorMessage()

  return useValidationStrategy(
    () => paymentProviders.indexOf(PaymentProvider.PayPal) > -1,
    {
      name: "PayPalValidator",
      validate: () => {
        return success()
      },
      save(): ValidationResult {
        return Promise.resolve(
          dispatch(
            PaymentProviderState.actions.addPaymentProvider(
              PaymentProvider.PayPal
            )
          )
        ).then(() => success())
      }
    }
  )
}

export const useGiftCardPaymentMethod = (
  paymentProviders: PaymentProvider[],
  cart: Cart | undefined
): ValidationStrategy & {
  token?: string
  card?: GiftCard
  isApplied: boolean
  showOtherPaymentMethods: () => boolean
  scrollToRef: React.MutableRefObject<HTMLElement | null>
  showOtherPaymentMethodMessage: () => boolean
  setWorkingGiftCard: (card: GiftCard) => Promise<GiftCard>
  applyGiftCard: (card: GiftCard) => Promise<GiftCard>
  unapplyGiftCard: () => Promise<void>
} => {
  const { t } = useTranslation("checkout")
  const { isShoppingInternationally } = useStoreContext()
  const dispatch = useAppDispatch()
  const client = useAxiosClient()
  const scrollToPayment = useScrollToElement()
  const paymentError = useTypedErrorApi(ErrorType.PaymentError)
  const giftCardState = useGiftCardState()
  const message = usePaymentErrorMessage()

  const currentGiftCard = giftCardState.card
  const isApplied = giftCardState.isApplied || false

  const cardHasSufficientBalance = (card: GiftCard | undefined) => {
    return (
      card &&
      cart &&
      card.balance.amount >
        (cart.taxedPrice?.totalGross || cart.totalPrice).centAmount
    )
  }

  const applyGiftCard = (card: GiftCard): Promise<GiftCard> => {
    return Promise.resolve(dispatch(GiftCardState.actions.setCard(card)))
      .then(() => dispatch(GiftCardState.actions.setApplied(true)))
      .then(() => {
        if (cardHasSufficientBalance(card)) {
          dispatch(
            PaymentProviderState.actions.setPaymentProviders([
              PaymentProvider.GiftCard
            ])
          )
        } else {
          dispatch(
            PaymentProviderState.actions.addPaymentProvider(
              PaymentProvider.GiftCard
            )
          )
        }
      })
      .then(() => {
        if (!cardHasSufficientBalance(card)) {
          scrollToPayment.scrollToElement()
        }
      })
      .then(() => card)
  }

  const unapplyGiftCard = (): Promise<void> => {
    const clearFromCart = () => {
      if (cart) {
        return client
          .post<{
            result: CancelledPendingPayments
            error?: { message?: string }
          }>(paymentApiUrl(`giftcard/cart/${cart.id}/cancel-pending`))
          .then(response => {
            if (response.status > 399) {
              Sentry.captureMessage(
                `Error in cancelling pending gift card payments on Cart ${cart.id}`,
                {
                  extra: {
                    cartId: cart.id
                  }
                }
              )
              return Promise.resolve(undefined)
            } else {
              return Promise.resolve(response.data.result)
            }
          })
      } else {
        return Promise.resolve(undefined)
      }
    }

    return Promise.resolve(dispatch(GiftCardState.actions.clearGiftCard()))
      .then(() => dispatch(GiftCardState.actions.setApplied(false)))
      .then(() => clearFromCart())
      .then(() =>
        dispatch(
          PaymentProviderState.actions.removePaymentProvider(
            PaymentProvider.GiftCard
          )
        )
      )
      .then(() => undefined)
  }

  const hasOtherPaymentMethod = paymentProviders.length > 1

  const strategy = useValidationStrategy(
    () => paymentProviders.indexOf(PaymentProvider.GiftCard) > -1,
    {
      name: "GiftCardValidator",
      validate: () => {
        if (isShoppingInternationally()) {
          return error(ErrorType.PaymentError, message)
        }

        if (!currentGiftCard) {
          return error(ErrorType.PaymentError, message)
        }

        if (
          !cardHasSufficientBalance(currentGiftCard) &&
          !hasOtherPaymentMethod
        ) {
          return error(ErrorType.PaymentError, {
            title: t("partialGiftCard"),
            message: t("pleaseChooseAnAdditionalPaymentMethod")
          })
        }

        if (
          !cardHasSufficientBalance(currentGiftCard) &&
          hasOtherPaymentMethod
        ) {
          return success()
        }

        return success()
      },

      save(): ValidationResult {
        if (currentGiftCard) {
          return client
            .post<{ result: { token: string } }>(
              paymentApiUrl("giftcard/token"),
              {
                cardNumber: currentGiftCard.cardNumber,
                cvc: currentGiftCard.cvc,
                value: currentGiftCard.balance
              }
            )
            .then(response => {
              if (response.status < 400) {
                dispatch(GiftCardState.actions.setToken(response.data.result))
                return success()
              } else {
                return error(ErrorType.PaymentError, message)
              }
            })
        } else {
          return error(ErrorType.PaymentError, message)
        }
      }
    }
  )

  const showOtherPaymentMethods = (): boolean => {
    return !cardHasSufficientBalance(currentGiftCard)
  }

  const showOtherPaymentMethodMessage = (): boolean => {
    const hasGiftCard = !!currentGiftCard
    const insufficientBalance = !cardHasSufficientBalance(currentGiftCard)
    return hasGiftCard && insufficientBalance
  }

  const setWorkingGiftCard = (card: GiftCard) =>
    Promise.resolve(dispatch(GiftCardState.actions.setCard(card)).payload)

  return {
    showOtherPaymentMethods,
    showOtherPaymentMethodMessage,
    setWorkingGiftCard,
    isApplied,
    applyGiftCard,
    unapplyGiftCard,
    scrollToRef: scrollToPayment.ref,
    ...giftCardState,
    ...strategy
  }
}
